.dish-collection {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dish-parent {
    min-width: 30%;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding: 0.25rem;
    border: 1px solid var(--secondaryColor);
    border-radius: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3.5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}

.dish-img {
    width: 1.8rem;
    margin: 0.5rem;
}

.dish-content {
    width: 75%;
    display: flex;
    justify-content: space-between
}

.dish-25 {
    width: 25%;
    text-align: center;
}

.dish-75 {
    text-align: center;
    width: 25%
}

@media screen and (max-width: 1300px) {

    .dish-parent {
        min-width: 45%;
    }
}

@media screen and (max-width: 900px) {
    .dish-collection {
        display: block;
    }

    .dish-parent {
        min-width: 80%;
    }
}