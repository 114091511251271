.global-settings-controls-container {
  text-align: right;
}

.global-settings-controls-container p {
  display: inline;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.6rem;
  margin-right: 20px;
}

.profile-form{
  margin-top: 20px;
}

.profile-button{
  margin-left: 10px;
}


@media screen and (max-width: 900px) {
  .global-settings-controls-container {
    text-align: left;
  }

  .global-settings-controls-container p {
    display: inherit;
  }
}
