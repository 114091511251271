.stock-grid {
    margin: 1rem;
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.stock-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0.7rem;
}

.stock-child-left {
    width: 30%;
    position: relative;
}

.stock-child-arrow {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 6rem;
    right: 0;
    margin: 0 1.5rem 0 0;
    padding-bottom: 0.75rem;
}

.stock-child-arrow .img {
    height: 100%;
    width: auto;
}

.stock-child-price {
    float: right;
    font-size: 4rem;
    display: flex;
}

.stock-child-price div {
    margin-right: 2rem;
    font-size: 2.5rem;
    margin-top: 1rem;
}

.stock-child-right {
    width: 70%;
}

.stock-child-title {
    font-size: 3rem;
    float: right;
    height: 2.5rem;
    word-wrap: revert;
    white-space: nowrap;
}

.stock-child-bar {
    float: right;
    width: 100%;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: var(--secondaryColor);
    margin: 0.5rem;
}

.stock-order-grid {
    margin: 1rem;
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.stock-active {
    color: var(--secondaryColor);
}

.stock-apply {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
}

.stock-apply button {
    margin-left: 2rem;
}


@media screen and (max-width: 600px) {
    .stock-grid {
        margin: 1rem;
        grid-gap: 0;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        grid-auto-rows: auto;
    }

    .stock-child {
        padding-right: 0;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    .stock-child-price {
        font-size: 2rem;
    }

    .stock-child-price div {
        margin-right: 8rem;
        font-size: 2rem;
        margin-top: 0;
    }

    .stock-child-title {
        font-size: 1.75rem;
    }

    .stock-child-arrow {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 3rem;
        right: 0;
        margin: 0 0.75rem 0 0;
        padding-bottom: 0.33rem;
    }
}