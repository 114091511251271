.order {
    box-sizing: border-box;
}

/* Remove extra left and right margins, due to padding */
.row-order {
    margin: 0 -5px;
}

.order-row {
    z-index: 1;
}

.order-title {
    height: 2.4rem;
    font-size: 2.1rem;
    font-weight: bold;
}

/* Clear floats after the columns */
.row-order:after {
    content: "";
    display: table;
    clear: both;
}

/* Float four columns side by side */
.column-order {
    float: left;
    width: 24.3%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 0.33%;
}

@media screen and (max-width: 1200px) {
    .column-order {
        width: 32%;
        display: block;
        margin-bottom: 20px;
        padding: 0 0.66%;
    }
}

@media screen and (max-width: 900px) {
    .column-order {
        width: 48%;
        display: block;
        margin-bottom: 20px;
        padding: 0 1%;
    }
    .order-title {
        height: 2.4rem;
    }
}

@media screen and (max-width: 600px) {
    .column-order {
        width: 96%;
        display: block;
        margin-bottom: 20px;
        padding: 0 2%;

    }
}


.card-order, .card-order-priority {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-color: var(--primaryLightColor);
    border-style: solid;
    border-width: 1px;
    padding: 16px;
    height: auto;
    min-height: 4.3rem;
    overflow: auto;
    background-color: var(--primaryColor);
    position: relative;
}

.card-order-priority {
    border-color: red;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

.order-bottom-image {
    height: 25px;
    width: auto;
}

@keyframes blink {
    50% {
        border-color: var(--primaryLightColor);
    }
}

.order-remark {
    margin-left: 1rem;
    color: var(--secondaryColor);
}

.selector-parent {
    display: flex;
    justify-content: space-between;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--secondaryColor);
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}

.order-top {
    display: flex;
    justify-content: space-between;

    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--secondaryColor);
    margin-bottom: 0.3rem;
}

.order-middle {

}

.selector-title {
    height: 2.4rem;
    font-size: 2.1rem;
    font-weight: bold;
}

.selector {
    float: right;
    margin-right: 3.15rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.selector-type {
    padding: 0.66rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: rgba(100, 100, 100, 0.4);
}

.order-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 0.75rem;
}

@media screen and (max-width: 900px) {
    .selector-parent {
        display: block;
        border-bottom-style: solid;
        border-width: 1px;
        border-color: var(--secondaryColor);
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    .selector-title {
        height: 1rem;
    }

    .selector {
        margin-right: 0;
        width: 100%;

    }
}

@media screen and (max-width: 600px) {
    .selector {
        float: left;
        width: 100%;
        margin-right: 0;
        overflow: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .selector a {
        padding-left: 0;
        margin-right: 2rem;
        display: inline-block;
    }
}