.navBar {
    background-color: var(--primaryColor);
    position: relative;
    z-index: 10;
    margin-bottom: 1rem;
}

.navList {
    top: 50%;
    position: absolute;
    float: right;
    right: 4rem;
}

.navBarContent {
    display: flex;
    justify-content: space-between;
}

.navBarGroup {
    display: flex;
    justify-content: left;
    margin-top: 2rem;
    max-height: 7rem;
    max-width: 27rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.navBar li img {
    display: inline;
    padding: 15px 0;
    vertical-align: middle;
}

.navBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    float: right;
}

.navBar li {
    padding: 0 25px;
    display: inline-block;
    vertical-align: middle;
}

.navList li {
    list-style-type: none;
}

.navBar li:last-child {
    padding-right: 0px;
}

li a,
li a:hover {
    text-decoration: none;
}

.login-modal-content {
    width: 50%;
    margin: auto;
    background-color: white;
    box-shadow: 1px 1px 1px #efefef;
    vertical-align: middle;
}

.logo {
    width: 75%;
    height: 75%;
    object-fit: cover;
}

.logo-text {
    position: absolute;
    opacity: 25%;
    margin-top: 3.5rem;
    margin-left: 1rem;
    font-size: 0.5rem;
}

.connected {
    display: flex;
    margin-top: 0.66rem;
    height: 1.8rem;
    width: 2.1rem;
}

.connected img {
    margin-right: 0.8rem;
}

@media screen and (max-width: 1023px) {
    .navBar {
        margin-bottom: 0.5rem;
    }

    .navBar ul {
        width: 78%;
        margin-top: 20px;
        border-top: 1px solid #dcdcdc14;
    }

    .navBarContent div {
        flex-direction: row;
    }

    .navBar button {
        /*margin-right: 1rem;*/
        margin-top: 1rem;
        margin-right: 1rem;
        height: 3rem;
        width: 3rem;
        /*margin: 23px;*/
    }


    .navList li {
        display: block;
        padding: 20px 0px;
    }

    .navBarGroup {
        margin-top: 1rem;
    }

    .logo {
        width: 80%;
        height: 80%;
    }
}

@media screen and (max-width: 480px) {
    .logo-text {
        margin-top: 0;
    }
}


