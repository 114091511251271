
.dish, .admin-dish {
    min-height: 50px;
}

.order-bag-item {
    min-height: 43px;
}

.dish, .order-bag-item, .admin-dish {
    padding: 0 1rem;
    margin-bottom: 1rem;
    background-color: rgba(100, 100, 100, 0.4);
    transition: height ease-in-out 0.5s;
}

.dish-item {
    height: 50px;
}

.order-item {
    height: 43px;
}

.dish-item, .order-item {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.order-item-description {
    display: flex;
}

.dish-icon, .type-selected, .type {
    width: 1.8rem;
    margin: 0.5rem;
}

.quantity-button {
    display: flex;
    font-size: 40px;
    font-weight: bold;
    left: 90%;
    margin-top: -0.25rem;
}

.order-item > .quantity-button {
    margin-top: -0.2rem;
}

.remove-button-dish {
    right: 3rem;
    margin-top: 0.5rem;
    position: absolute;
}

.dish-toggle-item, .order-bag-toggle-item {
    padding-bottom: 0.5rem;
}

.order-bag-toggle-item > ul {
    margin: 0;
}

.dish-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.dish-options-child {
    display: flex;
}

.option {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
}

.option > div > .not-checked {
    border-width: 1px;
    border-style: solid;
    border-color: white;
}

.option > div > .checked {
    border-width: 1px;
    border-style: solid;
    border-color: var(--secondaryColor);
}

.dish-remark {
    width: 100%;
}

.bag > form > input {
    margin-left: 1rem;
}

.dish-remark > input {
    min-height: 37px;
    background-color: #001a27;
    margin: auto -1rem;
    padding: 0 1rem;
    border-radius: 0;
}

.bag > form > button, .bag-checks {
    margin-left: 1rem;
}

.bag-checks {
    display: flex;
    margin-bottom: 1rem;
}

.bag-checks > form {
    margin-right: 1rem;
}

.icon {
    width: 1.5rem;
    margin: 0 0.5rem;
}

.type-selected, .type {
    margin: 0 0.5rem;
    padding: 0.2rem;
}

.type-selected {
    border-radius: 10%;
    border: var(--secondaryColor) solid 0.2rem;
}

.dish-price > .table-bar, .dish-name > .table-bar {
    margin-bottom: 0;
    width: 4rem;
    margin-left: 1rem;
}

.dish-price {
    padding-top: 0.5rem;
}

.dish-name {
    padding-top: 0.5rem;
}

.admin-dish {
    position: relative;
}
.buttons{
    padding-top: 0.5rem;
}

.add-dish-button {
    left: 50%;
    font-size: 40px;
    font-weight: bold;
}

@media screen and (max-width: 900px) {
    .dish-item {
        display: block;
        height: 100%;
        padding-top: 1rem;
    }

    .dish-icon{
        position: absolute;
        bottom: 0;
    }

    .buttons{
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1rem;
        padding-top: 0;
    }

    .dish-price {
        padding-top: 0;
    }

    .dish-name {
        padding-top: 0;
    }
}

@media screen and (max-width: 600px) {
    .dish-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        width: 100%;
    }

    .option {
        width: 100%;
        margin: 0.4rem 3rem;

    }

    .option > div > button {
        width: 100%;
    }

    .remove-button-dish {
        right: 1rem;
    }
}