.admin-panel{
    padding-top: 0.75rem;
}
.admin-panel button {
    margin-right: 10px;
}

.admin-child {
    margin-top: 32px;
}

.admin-form {
    float: left;
    width: 28%;
    margin: 1%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-color: var(--primaryLightColor);
    border-style: solid;
    border-width: 1px;
    padding: 16px;
    height: auto;
    overflow: auto;
    background-color: var(--primaryColor);
}

@media screen and (max-width: 1200px) {
    .admin-form {
        width: 43%;
    }
}

@media screen and (max-width: 900px) {
    .admin-form {
        width: 93%;
    }
}

@media screen and (max-width: 600px) {
    .admin-form {
        margin: 2%;
        width: 90%;
    }
}

.form-bottom {
    float: right;
}

.form-bottom  > img{
    width: 1.5rem;
    margin-left: 1rem;
}

.update-button{
    margin-bottom: 0.5rem;
}

.balance-user-admin{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.form-bottom-image{
    height: 25px;
    width: auto;
}
.menu-admin {
    margin-top: 1rem;
}

.selector-page {
    padding: 0.66rem 1rem;
    display: flex;
    justify-content: space-between;
    background-color: rgba(100, 100, 100, 0.4);
}

.new-printer{
    display: flex;
}

.new-printer > div{
    margin-top: 0.33rem;
    margin-left: 1rem;
}

.admin-user-bottom{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}