@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

body {
    margin: 0;
    color: #f2f2f2;
    font-family: "Source Sans Pro", sans-serif;
    overscroll-behavior: contain;
}

@font-face {
    font-family: 'MyFont-not-used';
    src: local('MyFont'), url(../fonts/MyriadProBoldCond.otf) format('otf');
}

:root {
    --primaryColor: hsla(200, 100%, 8%, 1);
    --primaryLightColor: hsla(190, 72%, 66%, 1);
    --secondaryColor: hsla(48, 100%, 51%, 1);
    --secondaryLightColor: hsla(49, 82%, 80%, 1);
    --customGrey: hsla(0, 0%, 95%, 1);
    --mdBreakpoint: 1024px;
    --xsBreakpoint: 480px;
    --primaryFont: "Open Sans", sans-serif;
    --secondaryFont: "Source Sans Pro", sans-serif;
    --styleFont: "Fira Sans", sans-serif;
    --columnSize: 200px;
    background-color: var(--primaryColor);
}

.appContent {
    width: 100%;
    margin: auto;
}

footer {
    margin-top: 50px;
    text-align: center;
}

.flex {
    display: flex;
    justify-content: space-between;
}

a:focus {
    text-decoration: none;
    color: var(--primaryLightColor);
}

.spinner {
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 8px;
    height: 8px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.collapse-content.collapsed {
    display: none;
}

.collapse-button {
    background-color: transparent;
    border: none;
}

.collapse-button:hover {
    color: var(--secondaryColor);
}

.collapse-button:focus-visible {
    outline: none;
}

.collapse-button svg {
    font-size: 4rem;
    display: inline;
    vertical-align: middle;
}

.chart {
    width: 100%;
    height: 100%;
    margin: 0 15px;
}

.line {
    border: 0.5px solid;
    border-color: var(--secondaryColor);
}

.hidden{
    visibility: hidden;
}

/* pagination */
.react-pagination-js-border-bottom > ul {
    display: flex;
    justify-content: center;
    width: 100%;
}
/* end-pagination */

.event-description.highlighted {
    background: linear-gradient(255deg, #001a27, #185a7ad0);
    background-size: 400% 400%;

    -webkit-animation: TimelineEventAnimation 19s ease infinite;
    -moz-animation: TimelineEventAnimation 19s ease infinite;
    animation: TimelineEventAnimation 19s ease infinite;
}

@-webkit-keyframes TimelineEventAnimation {
    0% {
        background-position: 0% 78%;
    }
    50% {
        background-position: 100% 23%;
    }
    100% {
        background-position: 0% 78%;
    }
}

@-moz-keyframes TimelineEventAnimation {
    0% {
        background-position: 0% 78%;
    }
    50% {
        background-position: 100% 23%;
    }
    100% {
        background-position: 0% 78%;
    }
}

@keyframes TimelineEventAnimation {
    0% {
        background-position: 0% 78%;
    }
    50% {
        background-position: 100% 23%;
    }
    100% {
        background-position: 0% 78%;
    }
}
