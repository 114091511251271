.reset-modal{
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.8);
}

.reset-modal-content {
    background-color: var(--primaryColor);
    color: #ffffff;
    margin: 50% auto;
    padding: 20px;
    border: 1px solid var(--secondaryColor);
    width: 80%;
}

.reset-modal-header{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.reset-modal-text{
    margin-bottom: 1rem;
}

.reset-buttons{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.password-modal {
    height: 37px;
    position: relative;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(100, 100, 100, 0.4);
    z-index: 0;
}

.password-modal > input {
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    outline: none;
    color: white;
    box-shadow: 0 4px 20px 0 transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow
}

@media screen and (min-width: 1023px) {
    .reset-modal-content {
        margin: 12.5% auto;
        width: 50%;
    }
}