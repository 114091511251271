.scrolling-wrapper{
  padding: 0.66rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: rgba(100, 100, 100, 0.4);
}

.order-header{
  display: flex;
  justify-content: space-around;
  margin: 0 1rem;
}

.moneyz{
  margin-top: 0.25rem;
}

.moneyz-active{
  margin-top: 0.25rem;
  color: #6ad2e7;
}

@media screen and (max-width: 900px) {
  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .scrolling-wrapper a {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    display: inline-block;
  }
}
