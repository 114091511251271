.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    font-family: var(--primaryFont);
    color: white;
    width: 33%;
    min-width: 250px;
    max-width: 350px;
}

.form-group {
    width: 96%;
}

img {
    width: 100%;
}

.login-container img {
    margin-bottom: 10px;
}

.login-form > div {
    margin-bottom: 15px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 0% 50%;
    }
}

div.return {
    position: absolute;
    left: 4rem;
    top: 5rem;
    width: 15rem;
    display: flex;
    justify-content: left;
}

div.return-line {
    position: absolute;
    top: 0.5rem;
    left: 3rem;
    width: 7.75rem;
    padding-bottom: 15px;
    border-bottom: #ffcd05 solid 2px;
}

.return-text {
    margin-left: 3rem;
    -moz-transition: all .1s ease-in;
    -o-transition: all .1s ease-in;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
}

div.return:hover .return-text{
    color: #ffcd05;
}

.arrow,
.arrow:before {
    position: absolute;
    left: 50%;
}

.arrow {
    width: 20px;
    height: 20px;
    top: 50%;
    left: -5%;
    margin: -11px 0 0 30px;
    -webkit-transform: rotate(135deg);
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
}

.arrow:before {
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    margin: -10px 0 0 -10px;
    border-left: none;
    border-top: none;
    border-right: 1px #fff solid;
    border-bottom: 1px #fff solid;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow;
}

@keyframes arrow {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate(-10px, -10px);
    }
}